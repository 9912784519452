import React, { useState, useEffect, useCallback } from 'react';
import InfoModal from "components/Modal/InfoModal";
import cameraIcon from "assets/camera.svg";
import Image from "next/image";
import Dropzone from 'react-dropzone';
import { useDispatch } from "react-redux";
import { setImageSearchDataAction } from '../../actions/usersAction';
import { useRouter } from 'next/router';
import useBreakpoint from '../../hooks/useBreakpoint';
import { Screen } from '../../constants/breakpoints';
import { toast } from 'react-toastify';
import {searchPerformedEvent} from "../../handlers/analytics/common";

interface ImageSearchPopupProps {
  isVisible: boolean;
  toggle: () => void;
}

const ImageSearchPopup: React.FC<ImageSearchPopupProps> = ({
                                                           isVisible,
                                                           toggle,
                                                         }) => {
  const [imageFileUrl, setImageFileUrl] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | undefined>('');

  const dispatch: any = useDispatch();
  const router: any = useRouter();

  let controller: any = null;

  const isLgScreen = useBreakpoint(Screen.LgScreen);
  const isXsScreen = useBreakpoint(Screen.XsScreen);

  useEffect(() => {
    if (!isVisible) {
      setImageFileUrl('');
      setError('');
    }
  }, [
    isVisible
  ]);

  const onClose = useCallback(() => {
    if (controller) {
      controller.abort();
    }
    toggle();
  }, [
    controller
  ]);

  const onDropAccepted = useCallback(async (acceptedFiles) => {
    let file: File | Blob;
    if (acceptedFiles[0].size > 2000000) {
      // Dynamically load compressorjs.js
      const Compressor = (await import('compressorjs')).default;
      file = await new Promise((resolve, reject) => {
        new Compressor(acceptedFiles[0], {
          quality: 0.8,
          success(result) {
            resolve(result);
          },
          error(err) {
            console.log(err.message);
            reject(err);
          },
        });
      })
    } else {
      file = acceptedFiles[0];
    }
    setImageFileUrl(URL.createObjectURL(file));
    setIsDragging(false);
    setError('');
    const formData = new FormData();
    formData.append('file', file);
    controller = new AbortController();
    fetch(`/api/imagesearch?tilesInRow=${isXsScreen ? 2 : isLgScreen ? 4 : 5}`, {
      method: 'POST',
      body: formData,
      signal: controller?.signal,
    })
      .then(async res => {
        if (!res.ok) {
          const errorRes = await res.json();
          throw new Error(errorRes.message);
        }
        return res.json();
      })
      .then((imageSearchData) => {
        dispatch(setImageSearchDataAction({
          ...imageSearchData,
          imageFileUrl: URL.createObjectURL(file),
        }));
        toggle();
        searchPerformedEvent();
        router.push({
          pathname: '/search',
          query: {
            type: 'image',
            imgHash: URL.createObjectURL(file).replace(/^[\w:/]+\//, '')
          }
        }, undefined, { shallow: true });
        controller = null;
      })
      .catch(e => {
        if (e.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.log('err: ', e.message);
          toast.error(e.message);
          toggle();
          controller = null;
        }
      });
  }, [controller]);

  return (
    <InfoModal
      isVisible={isVisible}
      onClose={onClose}
      headerLabel="Search With Any Image"
    >
      <div className="flex flex-col text-[#0A0A0A] max-w-[700px]">
        <div className="w-full">
          <Dropzone
            disabled={!!imageFileUrl}
            accept={{
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg']
            }}
            maxFiles={1}
            onDragEnter={() => {
              setIsDragging(true);
              setError('');
            }}
            onDragLeave={() => {
              setIsDragging(false);
              setError('');
            }}
            onDropAccepted={onDropAccepted}
            onDropRejected={(rejectedFiles) => {
              if (rejectedFiles[0]?.errors[rejectedFiles[0]?.errors.length - 1]?.message) {
                setError(rejectedFiles[0]?.errors[rejectedFiles[0]?.errors.length - 1]?.message);
              }
              setIsDragging(false);
            }}
            validator={(file) => {
              if (file.size > 20000000) {
                return {
                  code: "file-too-large",
                  message: "Can’t upload image. Image size should be less than 20MB"
                };
              } else if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
                return {
                  code: "file-invalid-type",
                  message: "Can’t upload. Supported file format: JPG, PNG only"
                };
              }

              return null;
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section className={`dropzone${isDragging ? ' dragging' : ''}`}>
                <div {...getRootProps({
                  className: 'w-full h-full flex flex-col justify-between'
                })}>
                  <input
                    {...getInputProps()}
                  />
                  <div className="flex-1 flex justify-center items-center space-x-2">
                    <div className={`flex items-center justify-center space-x-2.5${imageFileUrl ? ' opacity-1 w-full' : ' opacity-0 w-0'}`}>
                      {imageFileUrl && <Image
                        src={imageFileUrl}
                        width={40}
                        height={40}
                        objectFit='contain'
                      />}
                      <div>
                        <div className="text-neutral-700 text-sm font-normal">Uploading...</div>
                        <div className="relative mt-1.5 w-[93px] h-1 rounded-full bg-[#EDEDED]">
                          <div
                            className={`absolute left-0 bottom-0 h-1 rounded-full bg-[#424242] transition-width duration-1000${imageFileUrl ? ' w-full' : ' w-0'}`}
                          />
                        </div>
                      </div>
                    </div>
                    {!imageFileUrl && <>
                      <Image src={cameraIcon} />
                      <div>
                        {isDragging
                          ? <p className='text-neutral-700 text-[16px] font-semibold'>Drop image here</p>
                          : <p className='text-neutral-700 text-[16px] font-semibold'>Drag an image here or <u>Upload a
                            file</u></p>}
                        {!isDragging &&
                          <p className='text-neutral-500 text-[10px] font-normal'>File type: JPG, PNG | Max file size:
                            20MB</p>}
                      </div>
                    </>}
                  </div>
                  {error && <div className="w-full text-center text-red-800 text-[10px] font-normal">Can’t upload. {error}.</div>}
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    </InfoModal>
  );
};

export default ImageSearchPopup;