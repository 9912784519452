import React, {MouseEventHandler, ReactNode} from 'react';
import Image from 'next/image';
import closeIcon from 'assets/close.svg';

interface Props {
  name?: string,
  isVisible?: boolean,
  onClose: MouseEventHandler,
  rounded?: boolean,
  positionClass?: string,
  bgClass?: string,
  roundedClass?: string,
  children: ReactNode,
  paddingClass?: string,
  className?: string,
  alignRight?: boolean,
  headerLabel: ReactNode;
  headerIcon?: ReactNode,
  headerSubLabel?: ReactNode;
  headerCloseAdjacentComponent?: ReactNode;
}

function InfoModal(props: Props) {
  const {
    isVisible,
    onClose,
    bgClass = 'bg-white',
    roundedClass = 'rounded-[10px]',
    alignRight = false,
    headerLabel,
    headerCloseAdjacentComponent,
  } = props;
  if (!isVisible) {
    return null;
  }
  return (
    <>
      <div className={`bg-[rgba(0,0,0,0.25)] h-full w-screen fixed left-0 top-0 z-[500]`}
           onClick={onClose}>
      </div>
      <div className={`${bgClass} fixed ${alignRight ? 'top-0 right-0 h-full' : 'top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] max-h-[80vh] overflow-y-scroll no-scrollbar'} w-fit ${!alignRight ? roundedClass : ''} z-[500] shadow-[0_0_30px_0px_rgba(0,0,0,0.1)]`}
           onClick={e => e.stopPropagation()}
      >
        <div className='flex flex-col h-full w-full'>
          {/* Header */}
          <div className='sticky top-0 z-[500] bg-white flex items-center justify-between pl-6 pr-3 py-4'>
            <div className='flex space-x-2 items-start'>
              {props.headerIcon ? props.headerIcon : ''}
              <div>
                <div className='text-lg font-bold theme_1_black'>{headerLabel}</div>
                {props.headerSubLabel ? <div className='text-[#424242] text-xs font-normal leading-none'>{props.headerSubLabel}</div> : ''}
              </div>
            </div>
            {headerCloseAdjacentComponent ? <div className="flex-1">{headerCloseAdjacentComponent}</div> : ''}
            <div
              className={'cursor-pointer ml-auto w-[54px] h-[38px] text-right'}
              id='modal-close-button'
            >
              <Image
                src={closeIcon}
                alt={'close'}
                width={38}
                height={38}
                onClick={onClose}
              />
            </div>
          </div>
          <div className='sticky top-[54px] border-b'></div>

          {/* Body */}
          <div className='w-full h-full p-6 pt-6'>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

export default InfoModal;
