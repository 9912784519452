
import rnaAnalytics from "services/analytics/rnaAnalytics";
import {
  AnalyticActionType,
  AnalyticsEntityType,
  AnalyticsEvent,
  AnalyticsEventAction,
  AnalyticsPageSectionName
} from 'constants/analytics';
import { formatNumberInCommas, getFormattedBucketStringFromArray, roundOff } from "utils/misc";
import {Collection, ManualProductTag, Product} from "types";
import {useCallback} from "react";

export const productTileClickEvent = ({ productData, extras }: { productData: Product, extras?: { [key: string]: any } }, isSmScreen: boolean) => {
  rnaAnalytics.clickEvent({ 
    action: AnalyticsEventAction.ProductTileClicked,
    eventData: {
      [AnalyticsEvent.EntityID]: productData.productId,
      [AnalyticsEvent.EntityType]: AnalyticsEntityType.Product,
      [AnalyticsEvent.Extras]: {
        [AnalyticsEvent.Price]: productData.couponDiscountedPriceData?.formattedPrice || '--',
        [AnalyticsEvent.Inventory]: formatNumberInCommas(productData?.totalQuantity || 0),
        [AnalyticsEvent.SupplierRating]: `${roundOff(productData?.supplierRating, 1)}/${roundOff(productData?.maxSupplierRating)}`,
        [AnalyticsEvent.UnitsSold]: getFormattedBucketStringFromArray(productData?.automatedProductTags?.orderTag),
        [AnalyticsEvent.DeliveryRate]: productData?.automatedProductTags?.deliveryTag,
        ...(productData.videoUrl && {[AnalyticsEvent.VideoId]: productData.videoUrlArray[0]?.videoId}),
        ...(extras && extras),
        [AnalyticsEvent.TagData]: productData?.manualProductTags?.map((tag: ManualProductTag) => ({
          [AnalyticsEvent.TagName]: tag.type,
          [AnalyticsEvent.TagValue]: tag.count,
          [AnalyticsEvent.TagDescriptor]: tag.countSuffix,
        })),
        [AnalyticsEvent.TestingProduct]: productData?.isHeroProductTesting,
      }
    }
  }).send();
}

export const collectionFeedCarouselScrolledEvent = ({ 
  collectionId, 
  collectionName, 
  position, 
  direction, 
  type,
  testingCollection,
}: {
  collectionId: number;
  collectionName: string;
  position: number;
  direction: string;
  type: string;
  testingCollection?: boolean;
}) => {
  rnaAnalytics.clickEvent({
    action: AnalyticsEventAction.CollectionCarouselScrolled,
    eventData: {
      [AnalyticsEvent.EntityID]: collectionId,
      [AnalyticsEvent.EntityType]: AnalyticsEntityType.Collection,
      [AnalyticsEvent.Extras]: {
        [AnalyticsEvent.PageSectionName]: collectionName,
        [AnalyticsEvent.Position]: position,
        [AnalyticsEvent.Direction]: direction,
        [AnalyticsEvent.Type]: type,
        [AnalyticsEvent.TestingCollection]: testingCollection,
      }
    }
  }).send();
}

export const collectionCarouselViewedEvent = ({ 
  collectionId, 
  collectionName, 
  collectionPosition, 
  type,
  totalProducts,
  products,
}: {
  collectionId: number;
  collectionName: string;
  collectionPosition: number;
  type: string;
  totalProducts: number;
  products: { data: Product, position: number }[];
}, isSmScreen: boolean) =>{
  const data = products.map(({ data, position }) => ({
    [AnalyticsEvent.Id]: data.productId,  
    [AnalyticsEvent.ProductCCode]: data.productCode,  
    [AnalyticsEvent.Price]: data.couponDiscountedPriceData?.formattedPrice || '--',
    [AnalyticsEvent.SupplierRating]: `${roundOff(data?.supplierRating, 1)}/${roundOff(data?.maxSupplierRating)}`, 
    [AnalyticsEvent.Inventory]: formatNumberInCommas(data?.totalQuantity || 0),
    [AnalyticsEvent.Position]: position,
    [AnalyticsEvent.UnitsSold]: getFormattedBucketStringFromArray(data?.automatedProductTags?.orderTag),
    [AnalyticsEvent.DeliveryRate]: data?.automatedProductTags?.deliveryTag,
    ...(data.videoUrl && {[AnalyticsEvent.VideoId]: data.videoUrlArray[0].videoId}),
    [AnalyticsEvent.TagData]: data?.manualProductTags?.map((tag: ManualProductTag) => ({
      [AnalyticsEvent.TagName]: tag.type,
      [AnalyticsEvent.TagValue]: tag.count,
      [AnalyticsEvent.TagDescriptor]: tag.countSuffix,
    })),
    [AnalyticsEvent.TestingProduct]: data?.isHeroProductTesting,
  }));

  rnaAnalytics.impressionEvent({
    action: AnalyticsEventAction.CollectionCarouselViewed,
    eventData: {
      [AnalyticsEvent.EntityID]: collectionId,
      [AnalyticsEvent.EntityType]: AnalyticsEntityType.Collection,
      [AnalyticsEvent.Extras]: {
        [AnalyticsEvent.Position]: collectionPosition,
        [AnalyticsEvent.PageSectionName]: collectionName,
        [AnalyticsEvent.Type]: type,
        [AnalyticsEvent.TotalProducts]: totalProducts,
        [AnalyticsEvent.Data]: data,
      }
    }
  }).send();
}

export const collectionCarouselViewedDesktopEvent = (collectionWrapperElt: Element, collectionData: Collection, type?: string) => {
  // @ts-ignore
  const { collection, pt } = collectionWrapperElt?.dataset || {};
  const slickTrackElt = collectionWrapperElt.querySelectorAll(`.collection-${collection} .slick-list .slick-track [aria-hidden="false"] .product-collection-card`);

  const collectionPt = parseInt(pt)+1;
  const products: { data: Product, position: number }[] = [];

  slickTrackElt.forEach((elt, index) => {
    if (elt?.classList.contains('product-collection-wrapper')) {
      // @ts-ignore
      const product = collectionData.products.find(product => product.productId === parseInt(elt?.dataset?.product));
      product && products.push({ data: product, position: index+1 });
    }
  })

  collectionCarouselViewedEvent({
    collectionId: collectionData.collectionId, 
    collectionName: collectionData.collectionName, 
    collectionPosition: collectionPt, 
    type: type || (collectionPt > 1 ? AnalyticActionType.VScroll : AnalyticActionType.Load),
    totalProducts: collectionData.totalProducts,
    products,
  }, false);
}

export const categoryRibbonNavigationEvent = ({ category, direction }: {
  category: { data: { categoryName: string; categoryId: number }, position: number }[];
  direction: string;
}) => {
  const data = category.map(({ data, position }) => ({
    [AnalyticsEvent.ItemName]: data.categoryName,
    [AnalyticsEvent.Id]: data.categoryId,
    [AnalyticsEvent.Position]: position,
  }));

  rnaAnalytics.clickEvent({
    action: AnalyticsEventAction.CategoryRibbonNavigated,
    eventData: {
      [AnalyticsEvent.Extras]: {
        [AnalyticsEvent.Direction]: direction,
        [AnalyticsEvent.Data]: data,
      }
    }
  }).send();
}

export const categoryRibbonViewedEvent = ({ category }: {
  category: { data: { categoryName: string; categoryId: number }, position: number }[];
}) => {
  const data = category.map(({ data, position }) => ({
    [AnalyticsEvent.ItemName]: data.categoryName,
    [AnalyticsEvent.Id]: data.categoryId,
    [AnalyticsEvent.Position]: position,
  }));

  rnaAnalytics.impressionEvent({
    action: AnalyticsEventAction.CategoryRibbonViewed,
    eventData: {
      [AnalyticsEvent.Extras]: {
        [AnalyticsEvent.Data]: data,
      }
    }
  }).send();
}

export const categoryNavStripViewedEvent = ({ category }: {
  category: { data: { categoryName: string; categoryId: number }, position: number }[];
}, pageCategory: any) => {
  const data = category.map(({ data, position }) => ({
    [AnalyticsEvent.ItemName]: data.categoryName,
    [AnalyticsEvent.Id]: data.categoryId,
    [AnalyticsEvent.Position]: position,
  }));

  rnaAnalytics.impressionEvent({
    action: AnalyticsEventAction.CategoryNavbarViewed,
    eventData: {
      [AnalyticsEvent.EntityID]: pageCategory.categoryId,
      [AnalyticsEvent.EntityType]: AnalyticsEntityType.Category,
      [AnalyticsEvent.Extras]: {
        [AnalyticsEvent.Data]: data,
      }
    }
  }).send();
}

export const categoryNavStripScrolledEvent = ({ category }: {
  category: { data: { categoryName: string; categoryId: number }, position: number }[];
}, pageCategory: any, direction: string) => {
  const data = category.map(({ data, position }) => ({
    [AnalyticsEvent.ItemName]: data.categoryName,
    [AnalyticsEvent.Id]: data.categoryId,
    [AnalyticsEvent.Position]: position,
  }));

  rnaAnalytics.clickEvent({
    action: AnalyticsEventAction.CategoryNavbarScrolled,
    eventData: {
      [AnalyticsEvent.EntityID]: pageCategory.categoryId,
      [AnalyticsEvent.EntityType]: AnalyticsEntityType.Category,
      [AnalyticsEvent.Extras]: {
        [AnalyticsEvent.Data]: data,
        [AnalyticsEvent.Direction]: direction,
      }
    }
  }).send();
}

export const searchPerformedEvent = (isSearchString: boolean = false, searchString: string = '', searchCta?: string) => {
  rnaAnalytics.clickEvent({
    action: AnalyticsEventAction.SearchPerformed,
    eventData: {
      [AnalyticsEvent.Extras]: {
        [AnalyticsEvent.PageSectionName]: AnalyticsPageSectionName.TopNavbar,
        [AnalyticsEvent.SearchType]: isSearchString ? 'text' : 'image',
        ...(isSearchString && {[AnalyticsEvent.SearchString]: searchString}),
        ...(!isSearchString && searchCta && {[AnalyticsEvent.SearchCta]: searchCta}),
      }
    }
  }).send();
};
