import { apiCall } from "utils/api";
import { sellerStoreId } from "utils/auth";
import { getCountryPreferenceFromCookie } from "utils/misc";

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/search`;

export const getSearchSuggestions = async (queryString: string) => {
  const response = await apiCall({
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    url: `${HOST}/autocomplete/clout`,
    data: {
      queryTerm: queryString,
    storeId: sellerStoreId(),
    countryId: getCountryPreferenceFromCookie().id
    }
  })
  return response.data;
}

